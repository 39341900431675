import * as React from "react";
import { GridColumns, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { updateSessionsGridState } from "../../app/slices/sessionListTableStateSlice";
import CclPersistentDataGrid from "../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { EventDocument } from "../../services/types/search.service.types";
import { addCustomDataGridFilters } from "../../components/common/customDataGridFilters/customDataGridFilters";
import CclCommonLink from "../../components/common/cclCommonLink";

const COLUMNS = [
    {
        field: "eventnumber",
        headerName: "Session ID",
        type: "string",
        renderCell: (params: GridRenderCellParams) => (
            <CclCommonLink
                text={params.value}
                keyVal={params.value}
                to={`/session/${params.row.eventnumber}`}
            />
        ),
        width: 125,
    },
    {
        field: "projectCode",
        headerName: "Project ID",
        type: "string",
        renderCell: (params: GridRenderCellParams) => (
            <CclCommonLink
                text={params.value}
                keyVal={params.value}
                to={`/session/${params.row.eventnumber}`}
            />
        ),
        width: 125,
    },
    {
        field: "title",
        headerName: "Title",
        type: "string",
        renderCell: (params: GridRenderCellParams) => (
            <CclCommonLink
                text={params.value}
                keyVal={params.value}
                to={`/session/${params.row.eventnumber}`}
            />
        ),
        flex: 1,
    },
    {
        field: "statusKey",
        headerName: "Status",
        type: "string",
        valueGetter: (params: any) => {
            switch (params.row.statusKey) {
                case "1":
                    return "Active";
                case "2":
                    return "Cancelled";
                case "3":
                    return "Draft";
                case "4":
                    return "In Setup";
            }
            return "Unknown";
        },
        flex: 1,
    },
    {
        field: "startdate",
        headerName: "Start",
        valueGetter: (params: any) => new Date(params.row.startdate.slice(0, -1)),
        type: "date",
        renderCell: (params: GridRenderCellParams) => (
            <span>{params.value.toLocaleDateString()}</span>
        ),
        width: 150,
    },
    {
        field: "endDate",
        headerName: "End",
        valueGetter: (params: any) => new Date(params.row.endDate.slice(0, -1)),
        type: "date",
        renderCell: (params: GridRenderCellParams) => (
            <span>{params.value.toLocaleDateString()}</span>
        ),
        width: 150,
    },
    {
        field: "typeName",
        headerName: "Type",
        type: "string",
        width: 150,
    },
    {
        field: "programCode",
        headerName: "Code",
        type: "string",
        width: 50,
    },
    {
        field: "campusName",
        headerName: "Campus",
        width: 175,
        type: "string",
    },
    {
        field: "location",
        headerName: "Delivery Location",
        type: "string",
        width: 175,
    },
    {
        field: "hostPlatformName",
        headerName: "Hosting Platform",
        type: "string",
        width: 200,
    },
    {
        field: "surveyPlatformName",
        headerName: "Assessment Platform",
        type: "string",
        width: 200,
    },
    {
        field: "hasCompassSku",
        headerName: "Compass",
        type: "string",
        valueGetter: (params: GridValueGetterParams) => `${params.value ? "Yes" : "No"}`,
        width: 85,
    },
];

export interface SessionsListProps {
    sessions: EventDocument[] | undefined;
    loading: boolean;
    createSession: () => void;
    featureActive: boolean;
}

export const SessionsDataGridPro: React.FC<SessionsListProps> = (props) => {
    const stateSelector = useSelector((state: any) => state.sessionListTableState);
    const [sessions, setSessions] = React.useState<EventDocument[] | undefined>(props.sessions);

    React.useLayoutEffect(() => {
        setSessions(props.sessions);
    }, [props.sessions]);

    return (
        <CclPersistentDataGrid
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            data={sessions ?? []}
            loading={props.loading}
            stateSelector={stateSelector}
            userPreferenceId={1}
            getRowIdFn={(row: EventDocument) => row.eventnumber}
            actionCreator={updateSessionsGridState}
            hideToolbar={false}
            initState={JSON.stringify({
                sorting: { sortModel: [{ field: "startdate", sort: "asc" }] },
            })}
            resultBar={false}
            resultBarText={""}
            miscButtons={
                props.featureActive
                    ? [
                          {
                              id: "create-new-session-button",
                              caption: "Create Session",
                              onClick: props.createSession,
                              returnSelected: true,
                              restricted: true,
                          },
                      ]
                    : []
            }
            hideFooter={false}
        />
    );
};

export default SessionsDataGridPro;
