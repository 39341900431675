import React from "react";
import { Timeline, timelineOppositeContentClasses } from "@mui/lab";
import { AccessEvent } from "../../../services/types/accessEventTypes";
import { JournalEntry } from "../../../services/types/journalEntryTypes";
import HistoryTimeLineItem from "./sessionHistoryTimeLineItem";

export interface HistoryTimeLineProps {
    events: AccessEvent[];
    journalEntries: JournalEntry[];
    slimView?: boolean;
}

const HistoryTimeLine: React.FC<HistoryTimeLineProps> = (props) => {
    const allEvents: (AccessEvent | JournalEntry)[] = [...props.events, ...props.journalEntries];
    const sortedEvents = allEvents.sort(
        (a: AccessEvent | JournalEntry, b: AccessEvent | JournalEntry) =>
            a.createdUtc > b.createdUtc ? -1 : 1
    );
    return (
        <Timeline
            sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                },
            }}
        >
            {sortedEvents.map((item, i) => {
                return (
                    <HistoryTimeLineItem
                        key={
                            item.hasOwnProperty("accessEventId")
                                ? `evt-${(item as AccessEvent).accessEventId}`
                                : `jrn-${(item as JournalEntry).journalEntryId}`
                        }
                        item={item}
                        slimView={props.slimView}
                        isLast={allEvents.length - 1 === i}
                    />
                );
            })}
        </Timeline>
    );
};

export default HistoryTimeLine;
