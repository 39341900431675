import * as React from "react";
import { SelectOption } from "../selectOptions/selectOption";
import {
    CircularProgress,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
} from "@mui/material";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import CclButton from "../cclButtons/cclButton";

export type CclSelectOption = SelectOption & {
    disabled?: boolean;
    id?: string;
};

export type CclActionButtonProps = {
    fixedLabel: string;
    options: CclSelectOption[];
    onSelect: (val: string) => Promise<void>;
    disabled?: boolean;
};

const CclActionButton: React.FC<CclActionButtonProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
    const [working, setWorking] = React.useState<string | null>(null);

    const handleChange = async (val: string) => {
        setWorking(val);
        await props.onSelect(val);
        setMenuOpen(false);
        setWorking(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuOpen(false);
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <CclButton
                size="small"
                onClick={handleClick}
                endIcon={menuOpen ? <ArrowDropDown /> : <ArrowRight />}
                sx={{
                    whiteSpace: "nowrap",
                    display: "inline-flex",
                    alignItems: "center",
                    flexDirection: "row",
                }}
                mode={"primary"}
                disabled={props.disabled ?? false}
            >
                {props.fixedLabel}
            </CclButton>
            <Popover
                id={`${props.fixedLabel.toLowerCase()}-select-button`}
                open={menuOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <List dense component="nav">
                    {props.options &&
                        props.options.length > 0 &&
                        props.options.map((opt, i) => {
                            return (
                                <ListItemButton
                                    key={i}
                                    onClick={() => handleChange(opt.val)}
                                    disabled={opt.disabled ?? false}
                                >
                                    {working === opt.val ? (
                                        <ListItemIcon sx={{ minWidth: "auto", mr: 2 }}>
                                            <CircularProgress size={20} />
                                        </ListItemIcon>
                                    ) : null}
                                    <ListItemText primary={opt.option} />
                                </ListItemButton>
                            );
                        })}
                </List>
            </Popover>
        </React.Fragment>
    );
};

export default CclActionButton;
