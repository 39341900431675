import * as React from "react";
import CclDrawer from "../../../components/common/cclDrawer/cclDrawer";
import {
    Box,
} from "@mui/material";

import CclDrawerActionBar from "../../../components/common/cclDrawer/cclDrawerActionBar";
import CclButton from "../../../components/common/cclButtons/cclButton";
import CclLoadingButton from "../../../components/common/cclButtons/cclLoadingButton";

interface CreateSessionDrawerProps {
    open: boolean;
    onClose: () => void;
}

const CreateSessionDrawer: React.FC<CreateSessionDrawerProps> = (props) => {

    return (
        <CclDrawer
            title={"Create Session"}
            open={props.open}
            onClose={props.onClose}
            width="standard"
        >
            <Box sx={{ overflow: "hidden", height: 1 }}>
                <Box sx={{ overflow: "auto" }}>
                </Box>
                <CclDrawerActionBar>
                    <CclLoadingButton 
                        mode={"primary"}
                    >
                        Create & Register
                    </CclLoadingButton>
                    <CclLoadingButton 
                        mode={"primary"}
                        sx={{ mr: "auto"}}
                    >
                        Create
                    </CclLoadingButton>
                    <CclButton 
                        sx={{ float: "right", mr: 4 }} 
                        mode={"secondary"}
                        onClick={props.onClose}
                    >
                        Close
                    </CclButton>
                </CclDrawerActionBar>
            </Box>
        </CclDrawer>
    );
};

export default CreateSessionDrawer;
