import * as React from "react";
import CclDrawer from "../../../components/common/cclDrawer/cclDrawer";
import {
    Box,
    Chip,
    Divider,
    FormControlLabel,
    Grid,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import AdvancedSearchAttributeListItem from "./advancedSearchAttributeListItem";
import {
    useAdvancedProfileSearchMutation,
    useGetAllAttributeOptionsQuery,
    useGetAllCountriesQuery,
    useGetRolesQuery,
    useGetStatesandProvincesByCMKeyQuery,
    useGetUsedTimeZonesQuery,
} from "../../../services/cclTokenedEnterpriseCoachingApi";
import AdvancedSearchMultiSelect from "./advancedSearchMultiSelect";
import {
    AdvancedProfileSearchArgs,
    KeyValue,
    SavedSearchItem,
    SearchAttribute,
} from "../../../services/types/enterpriseCoachingApiTypes";
import AdvancedSearchMultiSelectWithStringIds from "./advancedSearchMultiSelectWithStringIds";
import AdvancedSearchSingleSelect from "./advancedSearchSingleSelect";
import { useGetAllCadresQuery } from "../../../services/cclTokenedEnterpirseCoachingCadreApi";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import CclLoadingButton from "../../../components/common/cclButtons/cclLoadingButton";
import CclButton from "../../../components/common/cclButtons/cclButton";
import CclDrawerActionBar from "../../../components/common/cclDrawer/cclDrawerActionBar";

interface AdvancedSearchControlProps {
    onSearchResults: (pkeys: number[] | undefined) => void;
    updateCurrentSearch: (search: SavedSearchItem) => void;
    pastSearch: any;
    open: boolean;
    onClose: () => void;
}

export interface AttributeSearch {
    attributeType: AutocompleteItem | undefined;
    attributeValue: AutocompleteItem | undefined;
    attributeLevel: AutocompleteItem | undefined;
    id: string;
}

export interface AutocompleteItem {
    id: string;
    label: string;
}

const AdvancedSearchControl: React.FC<AdvancedSearchControlProps> = (props) => {
    const [searchArgs, setSearchArgs] = React.useState<AdvancedProfileSearchArgs | undefined>(
        undefined
    );
    const [attributes, setAttributes] = React.useState<Array<AttributeSearch>>([
        {
            id: crypto.randomUUID(),
            attributeType: undefined,
            attributeValue: undefined,
            attributeLevel: undefined,
        },
    ]);
    const [shippingCountryKey, setShippingCountryKey] = React.useState<string>();
    const [city, setCity] = React.useState<string>(
        props.pastSearch?.shippingCity ? props.pastSearch?.shippingCity.label : ""
    );
    const [searchClicked, setSearchClicked] = React.useState<boolean>(false);
    const [allAttributesRequired, setAllAttributesRequired] = React.useState<boolean>(
        props.pastSearch.requireAll !== undefined ? props.pastSearch.requireAll : true
    );
    const { data: workPoolsList } = useGetAllCadresQuery();
    const { data: countryList } = useGetAllCountriesQuery();
    const { data: stateList, refetch: fetchStates } = useGetStatesandProvincesByCMKeyQuery(
        Number(shippingCountryKey),
        { skip: !shippingCountryKey }
    );
    const { data: rolesList } = useGetRolesQuery();
    const { data: allAttributeOptions } = useGetAllAttributeOptionsQuery();

    const [activeAttributes, setActiveAttributes] = React.useState<SearchAttribute[]>(
        props.pastSearch?.attributes ? props.pastSearch?.attributes : []
    );
    const [chips, setChips] = React.useState<{ label: string; id: string }[]>(
        props.pastSearch?.chips ? props.pastSearch?.chips : []
    );
    const [id, setId] = React.useState<string>(crypto.randomUUID());

    const [pastSearch, setPastSearch] = React.useState<SavedSearchItem>(
        props.pastSearch ? props.pastSearch : ({} as SavedSearchItem)
    );

    const [runSearch, { data: pKeys, error, isError, isLoading }] =
        useAdvancedProfileSearchMutation();
    const { logEvent } = useLogAccessEvent();
    const { data: usedTimeZones } = useGetUsedTimeZonesQuery();

    let workPools = workPoolsList;

    React.useEffect(() => {
        if (shippingCountryKey) {
            fetchStates();
        }
        let args = searchArgs;
        if (args) {
            args.shippingPmKeys = [];
            setSearchArgs(args);
        }
        // eslint-disable-next-line
    }, [shippingCountryKey]);

    React.useEffect(() => {
        if (searchClicked) props.onSearchResults(pKeys ? pKeys : []);
        // eslint-disable-next-line
    }, [pKeys]);

    React.useEffect(() => {
        let values = props.pastSearch;
        let args: AdvancedProfileSearchArgs = {
            shippingCmKeys: [],
            shippingPmKeys: [],
            searchAttributes: [],
            allAttributesRequired: allAttributesRequired,
        };

        if (values.workPools) {
            args.cKey = values.workPools.map((x: KeyValue) => Number(x.id));
        }
        if (values.roles) {
            args.prtKey = values.roles.map((x: KeyValue) => Number(x.id));
        }
        if (values.originCountry) {
            args.originCmKeys = [Number(values.originCountry.id)];
        }
        if (values.shippingCountry) {
            args.shippingCmKeys = [Number(values.shippingCountry.id)];
        }
        if (values.shippingState) {
            args.shippingPmKeys = [Number(values.shippingState.id)];
        }
        if (values.shippingCity) {
            args.shippingCity = values.shippingCity.label;
        }
        if (values.timeZones) {
            args.timeZones = values.timeZones.map((x: KeyValue) => x.id);
        }
        if (values.attributes) {
            args.searchAttributes = values.attributes;
        }
        setSearchArgs(args);
        if (values.requireAll !== undefined) {
            setAllAttributesRequired(values.requireAll);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.pastSearch]);

    let timeZonesMap = usedTimeZones
        ?.map((c) => {
            return { id: c, label: c };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

    const countryMap = countryList
        ?.map((c) => {
            return { id: c.cmKey.toString(), label: c.name };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

    const stateMap = stateList
        ?.map((c) => {
            return { id: c.spKey.toString(), label: c.name };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    const workPollMap = workPools
        ?.map((c) => {
            return { id: c.cKey.toString(), label: c.name };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

    let rolesMap = rolesList
        ?.map((c) => {
            return { id: c.prtKey.toString(), label: c.name };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

    const handleDeleteAttribute = (id: string) => {
        setAttributes(attributes.filter((x) => x.id !== id));
        setActiveAttributes(activeAttributes.filter((x) => x.id !== id));
        setChips(chips.filter((x) => x.id !== id));
        let temp = pastSearch;
        if (temp.chips) {
            temp.chips = temp.chips.filter((x) => x.id !== id);
            if (temp.attributes) {
                temp.attributes = temp.attributes.filter((x) => x.id !== id);
            }
            setPastSearch(temp);
            props.updateCurrentSearch(temp);
        }

        let args = searchArgs;
        if (args && args.searchAttributes) {
            args.searchAttributes = args.searchAttributes.filter((x) => x.id !== id);
            setSearchArgs(args);
        }
    };

    const gatherAttributes = (attribute: SearchAttribute) => {
        let temp = activeAttributes;
        for (let i = 0; i < temp.length; i++) {
            if (temp[i].id === attribute.id) {
                temp.splice(i, 1);
            }
        }
        temp.push(attribute);
        setActiveAttributes(temp);
    };

    const handleAttributeSelected = (attribute: SearchAttribute) => {
        if (!searchArgs) {
            let args: AdvancedProfileSearchArgs = {
                shippingCmKeys: [],
                shippingPmKeys: [],
                searchAttributes: [attribute],
                allAttributesRequired: allAttributesRequired,
            };
            setSearchArgs(args);
        } else {
            let args: AdvancedProfileSearchArgs = searchArgs;
            if (args.searchAttributes) {
                let tempArgs = args.searchAttributes.filter((x) => x.id !== attribute.id);
                args.searchAttributes = tempArgs.concat(attribute);
                args.allAttributesRequired = allAttributesRequired;
                setSearchArgs(args);
            } else {
                args.searchAttributes = [attribute];
                args.allAttributesRequired = allAttributesRequired;
                setSearchArgs(args);
            }
        }
    };

    const timeZoneHandler = (ids: string[]) => {
        let temp = pastSearch;
        if (temp) {
            temp.timeZones = mapIdToLabel(ids, timeZonesMap ? timeZonesMap : []);
            setPastSearch(temp);
            props.updateCurrentSearch(temp);
        }
        if (!searchArgs) {
            let args: AdvancedProfileSearchArgs = {
                timeZones: ids,
                shippingCmKeys: [],
                shippingPmKeys: [],
                allAttributesRequired: allAttributesRequired,
            };
            setSearchArgs(args);
        } else {
            let args: AdvancedProfileSearchArgs = searchArgs;
            args.timeZones = ids;
            setSearchArgs(args);
        }
    };

    const cKeyHandler = (ids: number[]) => {
        let temp = pastSearch;
        if (temp) {
            temp.workPools = mapIdToLabel(ids, workPollMap ? workPollMap : []);
            setPastSearch(temp);
            props.updateCurrentSearch(temp);
        }
        if (!searchArgs) {
            let args: AdvancedProfileSearchArgs = {
                cKey: ids,
                shippingCmKeys: [],
                shippingPmKeys: [],
                allAttributesRequired: allAttributesRequired,
            };
            setSearchArgs(args);
        } else {
            let args: AdvancedProfileSearchArgs = searchArgs;
            args.cKey = ids;
            setSearchArgs(args);
        }
    };

    const prtKeyHandler = (ids: number[]) => {
        let temp = pastSearch;
        if (temp) {
            temp.roles = mapIdToLabel(ids, rolesMap ? rolesMap : []);
            setPastSearch(temp);
            props.updateCurrentSearch(temp);
        }
        if (!searchArgs) {
            let args: AdvancedProfileSearchArgs = {
                prtKey: ids,
                shippingCmKeys: [],
                shippingPmKeys: [],
                allAttributesRequired: allAttributesRequired,
            };
            setSearchArgs(args);
        } else {
            let args: AdvancedProfileSearchArgs = searchArgs;
            args.prtKey = ids;
            setSearchArgs(args);
        }
    };

    const originCmKeyHandler = (ids: number[]) => {
        let temp = pastSearch;
        if (temp) {
            let val = mapIdToLabel(ids, countryMap ? countryMap : []);
            temp.originCountry = val[0];
            setPastSearch(temp);
            props.updateCurrentSearch(temp);
        }
        if (!searchArgs) {
            let args: AdvancedProfileSearchArgs = {
                originCmKeys: ids,
                shippingCmKeys: [],
                shippingPmKeys: [],
                allAttributesRequired: allAttributesRequired,
            };
            setSearchArgs(args);
        } else {
            let args: AdvancedProfileSearchArgs = searchArgs;
            args.originCmKeys = ids;
            setSearchArgs(args);
        }
    };

    const shippingCountryChanged = (cmKey: Array<number>) => {
        let temp = pastSearch;
        if (temp) {
            let val = mapIdToLabel(cmKey, countryMap ? countryMap : []);
            temp.shippingCountry = val[0];
            setPastSearch(temp);
            props.updateCurrentSearch(temp);
        }
        if (cmKey && cmKey.length > 0) {
            setShippingCountryKey(cmKey[0].toString());
        } else {
            setShippingCountryKey(undefined);
        }

        if (!searchArgs) {
            let args: AdvancedProfileSearchArgs = {
                shippingCmKeys: cmKey.length > 0 ? cmKey : [],
                shippingPmKeys: [],
            };
            setSearchArgs(args);
        } else {
            let args: AdvancedProfileSearchArgs = searchArgs;
            args.shippingCmKeys = cmKey.length > 0 ? cmKey : [];
            setSearchArgs(args);
        }
    };

    const shippingStateChanged = (pmKey: Array<number>) => {
        let temp = pastSearch;
        if (temp) {
            let val = mapIdToLabel(pmKey, stateMap ? stateMap : []);
            temp.shippingState = val[0];
            setPastSearch(temp);
            props.updateCurrentSearch(temp);
        }
        if (searchArgs) {
            let args: AdvancedProfileSearchArgs = searchArgs;
            args.shippingPmKeys = pmKey;
            setSearchArgs(args);
        }
    };

    const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let temp = pastSearch;
        if (temp) {
            temp.shippingCity = { id: e.target.value, label: e.target.value };
            setPastSearch(temp);
            props.updateCurrentSearch(temp);
        }
        setCity(e.target.value);
        if (!searchArgs) {
            let args: AdvancedProfileSearchArgs = {
                shippingPmKeys: [],
                shippingCmKeys: [],
                shippingCity: e.target.value,
                allAttributesRequired: allAttributesRequired,
            };
            setSearchArgs(args);
        } else {
            let args: AdvancedProfileSearchArgs = searchArgs;
            args.shippingCity = e.target.value;
            setSearchArgs(args);
        }
    };

    const handleAttributesRequiredChange = () => {
        let newAttributesRequiredValue = !allAttributesRequired;
        let temp = pastSearch;
        temp.requireAll = newAttributesRequiredValue;
        setPastSearch(temp);
        setAllAttributesRequired(newAttributesRequiredValue);
        if (searchArgs) {
            let args: AdvancedProfileSearchArgs = searchArgs;
            args.allAttributesRequired = newAttributesRequiredValue;
            setSearchArgs(args);
        }
    };

    const addAttribute = () => {
        const parentAttributes = allAttributeOptions
            ? allAttributeOptions.profileItemTypes
                  .map((x) => {
                      return { id: x.key, label: x.name };
                  })
                  .sort((a, b) => a.label.localeCompare(b.label))
            : [];

        let pastAttributes = pastSearch;
        pastAttributes.attributes = activeAttributes;
        props.updateCurrentSearch(pastAttributes);

        activeAttributes.forEach((a) => {
            handleAttributeSelected(a);
            for (let i = 0; i < parentAttributes.length; i++) {
                if (a.pitKey === parentAttributes[i].id) {
                    let label = "";
                    if (a.piKey) {
                        let profile: string | undefined = "";
                        if (a.pitKey !== 2) {
                            profile = allAttributeOptions?.profileItemTypes
                                .find((p) => p.key === a.pitKey)
                                ?.items.find((x) => x.key === a.piKey)?.name;
                        } else {
                            profile = allAttributeOptions?.languages.find(
                                (l) => l.key === a.piKey
                            )?.name;
                        }
                        if (profile !== undefined) {
                            label = `${profile}`;
                        }
                    }
                    let temp = chips;
                    if (temp.some((c) => c.id === a.id)) {
                        break;
                    } else {
                        temp.push({ label: label, id: a.id });
                        setChips(temp);
                        let past = pastSearch;
                        pastSearch.chips = temp;
                        setPastSearch(past);
                        props.updateCurrentSearch(past);
                        break;
                    }
                }
            }
        });
        setId(crypto.randomUUID());
    };

    const search = () => {
        setSearchClicked(true);
        if (searchArgs)
            runSearch(searchArgs)
                .unwrap()
                .then(() => {
                    logEvent("ProfileSearchExecuted", null);
                });
    };

    if (isError) {
        console.log("there was an error " + error);
    }

    const mapIdToLabel = (id: number[] | string[], map: KeyValue[]) => {
        let values: KeyValue[] = [];
        for (let i = 0; i < id.length; i++) {
            //eslint-disable-next-line
            let label = map.find((x) => x.id == id[i]);
            if (label) {
                values.push(label);
            }
        }
        return values;
    };

    const resetSearch = () => {
        props.updateCurrentSearch({} as SavedSearchItem);
        props.onClose();
    };

    return (
        <CclDrawer
            title={"Advanced Search"}
            open={props.open}
            onClose={props.onClose}
            width="standard"
        >
            <Box sx={{ overflow: "hidden", height: 1 }}>
                <Box sx={{ overflow: "auto" }}>
                    <Stack sx={{ m: 1 }} spacing={4}>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            Demographics
                        </Typography>
                        <AdvancedSearchMultiSelect
                            dataList={workPollMap ? workPollMap : []}
                            id="workPools"
                            label="Work Pools"
                            handleSelectionChanged={cKeyHandler}
                            defaultValue={
                                props.pastSearch?.workPools ? props.pastSearch?.workPools : []
                            }
                        />
                        <AdvancedSearchMultiSelect
                            dataList={rolesMap ? rolesMap : []}
                            id="roles"
                            label="Roles"
                            handleSelectionChanged={prtKeyHandler}
                            defaultValue={props.pastSearch?.roles}
                        />
                        <AdvancedSearchMultiSelectWithStringIds
                            dataList={timeZonesMap ? timeZonesMap : []}
                            id="time-zone"
                            label="Time Zone"
                            handleSelectionChanged={timeZoneHandler}
                            defaultValue={props.pastSearch?.timeZones}
                        />
                        <AdvancedSearchSingleSelect
                            dataList={countryMap ? countryMap : []}
                            id="origin-country"
                            label="Country of Origin"
                            handleSelectionChanged={originCmKeyHandler}
                            defaultValue={
                                props.pastSearch?.originCountry
                                    ? props.pastSearch?.originCountry
                                    : null
                            }
                        />
                        <AdvancedSearchSingleSelect
                            dataList={countryMap ? countryMap : []}
                            id="shipping-country"
                            label="Country"
                            handleSelectionChanged={shippingCountryChanged}
                            defaultValue={
                                props.pastSearch?.shippingCountry
                                    ? props.pastSearch?.shippingCountry
                                    : null
                            }
                        />
                        <AdvancedSearchSingleSelect
                            dataList={stateMap ? stateMap : []}
                            id="shipping-state"
                            label="State"
                            handleSelectionChanged={shippingStateChanged}
                            defaultValue={
                                props.pastSearch?.shippingState
                                    ? props.pastSearch?.shippingState
                                    : null
                            }
                        />
                        <TextField
                            size="small"
                            id="shipping-city-tf"
                            label="City"
                            variant="outlined"
                            value={city}
                            onChange={handleCityChange}
                            fullWidth
                        />
                    </Stack>

                    <Divider sx={{ mt: 4, mb: 4 }} />

                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Attributes
                    </Typography>
                    {chips.length > 0 && (
                        <>
                            <Divider sx={{ mt: 2, mb: 2 }} />
                            <Stack direction={"row"}>
                                {chips.map((chip) => (
                                    <Chip
                                        key={chip.id}
                                        onDelete={() => {
                                            handleDeleteAttribute(chip.id);
                                        }}
                                        label={chip.label}
                                        sx={{ m: 1 }}
                                    />
                                ))}
                            </Stack>
                            <Divider sx={{ mt: 2, mb: 2 }} />
                        </>
                    )}
                    <Grid item xs={12} md={12} lg={12} sx={{ padding: 0 }}>
                        <AdvancedSearchAttributeListItem
                            handleAttributeSelected={gatherAttributes}
                            addAttribute={addAttribute}
                            allAttributeOptions={allAttributeOptions}
                            id={id}
                        />
                    </Grid>
                </Box>

                <CclDrawerActionBar>
                    <CclLoadingButton
                        loading={isLoading}
                        onClick={search}
                        disabled={!searchArgs}
                        sx={{ m: 1 }}
                        mode={"primary"}
                    >
                        Search
                    </CclLoadingButton>
                    <CclButton sx={{ m: 1 }} onClick={props.onClose} mode={"secondary"}>
                        Close
                    </CclButton>
                    <Stack direction={"row"} sx={{ m: 1, mr: "auto" }}>
                        {chips.length > 1 ? (
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={chips.length <= 1}
                                        checked={allAttributesRequired}
                                        onChange={handleAttributesRequiredChange}
                                    />
                                }
                                label={"Require All Attributes"}
                            />
                        ) : null}
                    </Stack>
                    <CclButton
                        mode={"primary"}
                        sx={{ float: "right", m: 1, mr: 4 }}
                        onClick={() => {
                            resetSearch();
                        }}
                    >
                        Reset
                    </CclButton>
                </CclDrawerActionBar>
            </Box>
        </CclDrawer>
    );
};

export default AdvancedSearchControl;
