import {
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
    TimelineDot,
    TimelineConnector,
} from "@mui/lab";
import React from "react";
import AccessEventTimeLineItemContent from "./accessEventTimelineContent";
import JournalEntryTimeLineItemContent from "./journalEntryTimeLineItemContent";
import { AccessEvent } from "../../../services/types/accessEventTypes";
import { JournalEntry } from "../../../services/types/journalEntryTypes";

export interface AccessEventTimeLineItemProps {
    item: AccessEvent | JournalEntry;
    isLast: boolean;
    slimView?: boolean;
}

const HistoryTimeLineItem: React.FC<AccessEventTimeLineItemProps> = (props) => {
    const dt = new Date(props.item.createdUtc);
    const itemIsAccessEvent = props.item.hasOwnProperty("accessEventId");
    const rgb = itemIsAccessEvent ? "rgb(240, 240, 240)" : "rgb(255, 255, 240)";
    return (
        <TimelineItem>
            <TimelineOppositeContent align="right" variant="body2" color="text.secondary">
                {dt.toLocaleString()}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot sx={{ backgroundColor: { rgb } }} />
                {props.isLast ? null : <TimelineConnector />}
            </TimelineSeparator>
            {itemIsAccessEvent ? (
                <AccessEventTimeLineItemContent event={props.item as AccessEvent} />
            ) : (
                <JournalEntryTimeLineItemContent slimView={props.slimView} entry={props.item as JournalEntry} />
            )}
        </TimelineItem>
    );
};

export default HistoryTimeLineItem;
