import { CclInfoTableRowTemplate } from "../../../../../components/common/cclInfoTable/cclInfoTable";
import { eInfoTableValueFieldEditType } from "../../../../../components/common/cclInfoTable/cclInfoTableValueField";

export const CclInfoTblAssessmentStatusSessionDatesTemplate: CclInfoTableRowTemplate[] = [
    {
        id: "preworkInvitationDate",
        fieldType: "Date",
        editable: false,
        label: "Pre-work Invitation",
        required: true,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.DatePicker,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "preworkInvitationEnabled",
        fieldType: "Date",
        editable: true,
        label: "Pre-work Invitation Enabled",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Checkbox,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "surveyCompletionDate",
        fieldType: "Date",
        editable: false,
        label: "Survey Completion",
        required: true,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.DatePicker,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "reminder1",
        fieldType: "Date",
        editable: false,
        label: "Reminder 1",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.DatePicker,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "reminder2",
        fieldType: "Date",
        editable: false,
        label: "Reminder 2",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.DatePicker,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "reminder3",
        fieldType: "Date",
        editable: false,
        label: "Reminder 3",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.DatePicker,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "remindersEnabled",
        fieldType: "Date",
        editable: true,
        label: "Reminders Enabled",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Checkbox,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
];
