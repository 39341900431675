import React from "react";
import { Participant } from "../../../../../services/types/enterpriseParticipantApiTypes";
import AssessmentListDataGridPro from "./assessmentListDataGrid";
import { AlertColor, Box } from "@mui/material";
import AddAssessmentDrawer from "../../../../sessions/sessionDetail/general/assessmentSetup/addAssessmentDrawer";
import AddDocumentDrawer from "../../../../sessions/sessionDetail/general/assessmentSetup/addDocumentDrawer";
import { AssessmentListItem } from "../../../../../services/types/sessionDetailTypes";
import {
    useGetSessionSetUpAssessmentsByEventKeyQuery,
    useLazyGetAIKeybyDocKeyQuery,
} from "../../../../../services/cclTokenedSessionApi";
import { AssetDownloadRequest } from "../../../../../services/types/assetApiTypes";
import { FileDownloadService } from "../../../../../services/fileDownloadService/fileDownloadService";

interface AssessmentListPanelProps {
    registration: Participant | undefined;
}

const AssessmentListPanel: React.FC<AssessmentListPanelProps> = (props) => {
    const downloadService = new FileDownloadService();

    const [showAddAssessmentDrawer, setShowAddAssessmentDrawer] = React.useState(false);
    const [showAddDocumentDrawer, setShowAddDocumentDrawer] = React.useState(false);
    const [getDocsAiKey] = useLazyGetAIKeybyDocKeyQuery();
    const {
        data: sessionAssessments,
        isSuccess: assessmentsSuccess,
        isLoading: assessmentsLoading,
    } = useGetSessionSetUpAssessmentsByEventKeyQuery(props.registration?.esKey.toString() ?? "", {
        skip: !props.registration?.esKey,
    });

    const handleButtonClick = (buttonId: string) => {
        switch (buttonId) {
            case "addassessment":
                setShowAddAssessmentDrawer(true);
                break;
            case "adddocument":
                setShowAddDocumentDrawer(true);
                break;
        }
    };

    const viewDocument = (docId: number) => {
        getDocsAiKey(docId)
            .unwrap()
            .then((aidata) => {
                if (aidata && aidata?.aI_Key > 0) {
                    let doc = sessionAssessments?.documentOptionsList.filter(
                        (d) => d.documentID === docId
                    );
                    if (doc && doc.length > 0) {
                        let request: AssetDownloadRequest = {
                            fname: doc[0].documentName,
                            aikeys: [aidata.aI_Key],
                            flatzip: false,
                        };
                        downloadService.DownloadAssets(request).then(() => {});
                    }
                } else {
                    //SHOW ERROR
                }
            });
    };

    return (
        <Box width={1}>
            {showAddAssessmentDrawer && props.registration?.esKey != null ? (
                <AddAssessmentDrawer
                    open={true}
                    esKey={props.registration?.esKey}
                    onCancel={() => setShowAddAssessmentDrawer(false)}
                    onAdd={function (newItem: AssessmentListItem): void {
                        throw new Error("Function not implemented.");
                    }}
                    assessmentOptionsList={sessionAssessments?.assessmentOptionsList ?? []}
                    showSnackbar={function (message: string, severity: AlertColor): void {
                        throw new Error("Function not implemented.");
                    }}
                />
            ) : null}
            {showAddDocumentDrawer && props.registration?.esKey != null ? (
                <AddDocumentDrawer
                    open={true}
                    esKey={props.registration?.esKey}
                    onCancel={() => setShowAddDocumentDrawer(false)}
                    onAdd={function (newItem: AssessmentListItem): void {
                        throw new Error("Function not implemented.");
                    }}
                    onViewDocument={viewDocument}
                    documentOptionsList={sessionAssessments?.documentOptionsList ?? []}
                    showSnackbar={function (message: string, severity: AlertColor): void {
                        throw new Error("Function not implemented.");
                    }}
                />
            ) : null}
            <AssessmentListDataGridPro handleButtonClick={handleButtonClick} />
        </Box>
    );
};

export default AssessmentListPanel;
