import { CclInfoTableRowTemplate } from "../../../../../components/common/cclInfoTable/cclInfoTable";

export const cclInfoTblAssessmentStatusSessionInfoTemplate: CclInfoTableRowTemplate[] = [
    {
        id: "title",
        fieldType: "string",
        editable: false,
        label: "Session Title",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "sessionCode",
        fieldType: "string",
        editable: false,
        label: "Project ID",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "startDateTimeUTC",
        fieldType: "Date",
        editable: false,
        label: "Start Date",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "hostingPlatform",
        fieldType: "string",
        editable: false,
        label: "Hosting Platform",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "assessmentPlatform",
        fieldType: "string",
        editable: false,
        label: "Assessment Platform",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
];
