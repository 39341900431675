import { Switch } from "@mui/material";
import { addCustomDataGridFilters } from "../../../../../components/common/customDataGridFilters/customDataGridFilters";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import CclDataGridToolbar from "../../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";

type FakeData = {
    id: number;
    sequencenum: string;
    type: string;
    title: string;
    language: string;
    status: string;
    scored: string;
    cclmasterkey: string;
    enabled: boolean;
};

export interface AssessmentListDataGridProProps {
    handleButtonClick: (buttonId: string) => void;
}

export const AssessmentListDataGridPro: React.FC<AssessmentListDataGridProProps> = (props) => {
    var fakedata: FakeData[] = [
        {
            id: 1,
            sequencenum: "1",
            type: "Assessment",
            title: "Assessment 1",
            language: "English",
            status: "Active",
            scored: "Yes",
            cclmasterkey: "12345",
            enabled: true,
        },
        {
            id: 2,
            sequencenum: "2",
            type: "Document",
            title: "Document 1",
            language: "English",
            status: "Inactive",
            scored: "No",
            cclmasterkey: "67890",
            enabled: false,
        },
    ];

    const handleButtonClick = (buttonId: string) => {
        switch (buttonId) {
            case "addassessment":
                console.log("Add Assessment button clicked");
                break;
            case "adddocument":
                console.log("Add Document button clicked");
                break;
            default:
                console.log("Unknown button clicked");
        }
        props.handleButtonClick(buttonId);
    };

    const COLUMNS: GridColDef[] = [
        {
            field: "sequencenum",
            headerName: "Seq. #",
            type: "string",
            flex: 0.2,
        },
        {
            field: "type",
            headerName: "Type",
            type: "string",
            flex: 0.2,
        },
        {
            field: "title",
            headerName: "Title",
            type: "string",
            flex: 1,
        },
        {
            field: "language",
            headerName: "Language",
            type: "string",
            flex: 0.2,
        },
        {
            field: "status",
            headerName: "Status",
            type: "string",
            flex: 0.2,
        },
        {
            field: "scored",
            headerName: "Scored",
            type: "string",
            flex: 0.2,
        },
        {
            field: "cclmasterkey",
            headerName: "CCL Master Key",
            type: "string",
            flex: 0.2,
        },
        {
            field: "enabled",
            headerName: "Enable / Disable",
            renderCell: (params: GridRenderCellParams) => {
                return <Switch size="small" checked={params.row.enabledisable} />;
            },
            type: "boolean",
            flex: 0.2,
        },
    ];

    return (
        <DataGridPro
            columns={addCustomDataGridFilters(COLUMNS)}
            initialState={{
                sorting: { sortModel: [{ field: "participantName", sort: "asc" }] },
            }}
            rows={fakedata}
            getRowId={(row: FakeData) => row.id}
            components={{ Toolbar: CclDataGridToolbar }}
            componentsProps={{
                toolbar: {
                    showSaveState: true,
                    hideSettingsButtons: true,
                    miscButtons: [
                        {
                            id: "addassessment",
                            caption: "Add Assessment",
                            disable: false,
                            returnSelected: false,
                            restricted: false,
                            onClick: () => handleButtonClick("addassessment"),
                        },
                        {
                            id: "adddocument",
                            caption: "Add Document",
                            disable: false,
                            returnSelected: false,
                            restricted: false,
                            onClick: () => handleButtonClick("adddocument"),
                        },
                    ],
                    btnClick: handleButtonClick,
                },
            }}
            autoHeight
        />
    );
};

export default AssessmentListDataGridPro;
