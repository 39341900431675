import { TimelineContent } from "@mui/lab";
import React from "react";
import { Box, Typography } from "@mui/material";
import { JournalEntry } from "../../../services/types/journalEntryTypes";

export interface JournalEntryTimeLineItemContentProps {
    entry: JournalEntry;
    slimView?: boolean;
}

const JournalEntryTimeLineItemContent: React.FC<JournalEntryTimeLineItemContentProps> = (props) => {

    const sx = () => {
        if (props.slimView) {
            return {
                pl: 3,
                pb: 2,
                pt: 1,
                borderWidth: "1px",
                borderColor: "gray",
                borderStyle: "solid",
                borderRadius: 3,
                backgroundColor: "white",
            };
        }
        return {
            pl: 4,
            pb: 4,
            pt: 3,
            borderWidth: "1px",
            borderColor: "gray",
            borderStyle: "solid",
            borderRadius: 3,
            backgroundColor: "white",
        };
    }

    return (
        <TimelineContent>
            <Box
                sx={sx()}
            >
                <Typography variant="body1">{props.entry.entry}</Typography>
                <Typography variant="body2" color={"Gray"}>
                    <b>Author: </b>
                    {props.entry.createdByUser}
                </Typography>
            </Box>
        </TimelineContent>
    );
};

export default JournalEntryTimeLineItemContent;
