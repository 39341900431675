import React from "react";
import { Box, Stack } from "@mui/material";
import CclDrawer from "../../../../../../../components/common/cclDrawer/cclDrawer";
import CclInfoTable from "../../../../../../../components/common/cclInfoTable/cclInfoTable";
import { useGetCclParticipantQuery } from "../../../../../../../services/cclTokenedEnterpriseParticipantApi";
import { CclInfoTblParticipantContactInfoTemplate } from "./cclInfoTblParticipantContactInfoTemplate";
import CclButton from "../../../../../../../components/common/cclButtons/cclButton";
import CclDrawerActionBar from "../../../../../../../components/common/cclDrawer/cclDrawerActionBar";

export type ParticipantContactDrawerProps = {
    open: boolean;
    onClose: () => void;
    esiKey: number;
};

const ParticipantContactDrawer: React.FC<ParticipantContactDrawerProps> = (props) => {
    const { data: participant, isSuccess } = useGetCclParticipantQuery(props.esiKey.toString(), {
        skip: props.esiKey == null,
    });
    return (
        <CclDrawer open={props.open} onClose={props.onClose} title="Contact Information">
            <Box>
                <Stack spacing={2}>
                    {isSuccess && participant !== undefined ? (
                        <CclInfoTable
                            template={CclInfoTblParticipantContactInfoTemplate}
                            data={participant}
                        />
                    ) : null}

                    <CclDrawerActionBar>
                        <CclButton onClick={props.onClose} mode={"primary"}>
                            Close
                        </CclButton>
                    </CclDrawerActionBar>
                </Stack>
            </Box>
        </CclDrawer>
    );
};

export default ParticipantContactDrawer;
