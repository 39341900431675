import React from "react";
import CclDrawer from "../../../../../../../components/common/cclDrawer/cclDrawer";
import { ParticipantAdditionalInstrumentStatus } from "../../../../../../../services/types/scoringStatusTypes";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import CclDrawerActionBar from "../../../../../../../components/common/cclDrawer/cclDrawerActionBar";
import CclButton from "../../../../../../../components/common/cclButtons/cclButton";
import { Box } from "@mui/material";
import CircleCheckIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

export type AdditionalInstrumentsDrawerProps = {
    open: boolean;
    onClose: () => void;
    instruments: ParticipantAdditionalInstrumentStatus[];
};

const AdditionalInstrumentsDrawer: React.FC<AdditionalInstrumentsDrawerProps> = (props) => {
    const COLUMNS: GridColDef[] = [
        {
            field: "instrumentName",
            headerAlign: "center",
            headerName: "Instrument",
            type: "string",
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
        },
        {
            field: "scorable",
            headerAlign: "center",
            headerName: "Status",
            disableColumnMenu: true,
            valueGetter: (params: GridRenderCellParams) =>
                params.value ? "Scorable" : "Not Scorable",
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Box
                        display={"flex"}
                        alignContent={"center"}
                        width={"100%"}
                        sx={{ paddingLeft: 4 }}
                    >
                        {params.value ? (
                            <CircleCheckIcon fontSize="small" style={{ color: "#5E9D45" }} />
                        ) : (
                            <RemoveCircleIcon fontSize="small" style={{ color: "#EF5800" }} />
                        )}
                        <span style={{ paddingLeft: 4 }}>
                            {params.value ? "Scorable" : "Not Scorable"}
                        </span>
                    </Box>
                );
            },
            width: 150,
            sortable: false,
        },
    ];

    return (
        <CclDrawer open={props.open} onClose={props.onClose} title="Additional Instruments">
            <Box width="100%" height="100%">
                <DataGridPro
                    getRowId={(row) => row.instrumentId}
                    columns={COLUMNS}
                    rows={props.instruments}
                    disableSelectionOnClick={true}
                    initialState={{
                        sorting: { sortModel: [{ field: "instrumentName", sort: "asc" }] },
                    }}
                    autoHeight
                />
            </Box>
            <CclDrawerActionBar>
                <CclButton onClick={props.onClose} mode={"primary"}>
                    Close
                </CclButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};

export default AdditionalInstrumentsDrawer;
