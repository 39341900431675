import React, { ReactElement } from "react";
import {
    AlertColor,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {
    useGetJournalTemplatesQuery,
    useSaveJournalEntryMutation,
} from "../../../services/cclTokenedJournalEntryApi";
import { AddJournalEntriesRequest, JournalEntry } from "../../../services/types/journalEntryTypes";
import CclErrorDialog from "../cclErrorDialog";
import CclButton from "../cclButtons/cclButton";
import CclDrawer from "../cclDrawer/cclDrawer";
import CclLoadingButton from "../cclButtons/cclLoadingButton";
import CclDrawerActionBar from "../cclDrawer/cclDrawerActionBar";
import CclAlertSnackbar from "../cclAlertSnackbar";
import HistoryTimeLine from "../historyTimeline/historyTimeline";

export type JournalEntryDrawerProps = {
    bannerElement?: ReactElement;
    journalCategory: number;
    journalCategoryData: string[];
    journalHistory: JournalEntry[];
    alert?: JSX.Element;
    alertColor?: AlertColor;
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    onSaveError: (msg: string) => void;
};

const JournalEntryDrawer: React.FC<JournalEntryDrawerProps> = (props) => {
    const [selectedTemplateId, setSelectedTemplateId] = React.useState<number>(-1);
    const [entryText, setEntryText] = React.useState<string>("");
    const [showErrorDialog, setShowErrorDialog] = React.useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor>("info");
    const [saveJournalEntry, { isLoading }] = useSaveJournalEntryMutation();
    const { data: templates } = useGetJournalTemplatesQuery(props.journalCategory);

    const templateOptions =
        templates != null
            ? [
                  {
                      journalEntryTemplateId: -1,
                      journalCategoryId: -1,
                      subject: "None",
                      content: "",
                      active: true,
                  },
                  ...templates,
              ]
            : [];
    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const handleClose = () => {
        setEntryText("");
        props.onClose();
    };

    const handleSave = () => {
        if (entryText.trim() === "" && selectedTemplateId < 1) {
            setShowErrorDialog(true);
        } else {
            const request: AddJournalEntriesRequest = {
                entry: entryText,
                journalTemplateId: selectedTemplateId > 0 ? selectedTemplateId : undefined,
                categoryId: props.journalCategory,
                categoryData: props.journalCategoryData,
            };
            saveJournalEntry(request)
                .unwrap()
                .then(() => {
                    setEntryText("");
                    var msg = props.journalCategoryData.length > 1 ? "Entries" : "Entry";
                    ShowSnackbarElement(`Journal ${msg} Created`, "success");
                    props.onSave();
                })
                .catch(() => {
                    var msg = props.journalCategoryData.length > 1 ? "entries" : "entry";
                    props.onSaveError(`Failed to save journal ${msg}.`);
                });
        }
    };

    return (
        <>
            <CclErrorDialog
                open={showErrorDialog}
                onOk={() => setShowErrorDialog(false)}
                msg="Journal entry cannot be blank or empty."
            />
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <CclDrawer open={props.open} onClose={handleClose} title="Create Journal Entry">
                <Box component="form" noValidate autoComplete="off">
                    <Stack direction="column" spacing={2}>
                        {props.bannerElement ? props.bannerElement : null}
                        {templateOptions.length > 0 ? (
                            <React.Fragment>
                                <FormControl>
                                    <InputLabel id="template-select-dropdown">
                                        Select Message
                                    </InputLabel>
                                    <Select
                                        defaultValue={templateOptions[0].journalEntryTemplateId}
                                        id="template-select-dropdown"
                                        label="Entry Template"
                                        onChange={(e) => {
                                            const templateId = e.target.value as number;
                                            const content =
                                                templateId === -1
                                                    ? ""
                                                    : templateOptions.find(
                                                          (t) =>
                                                              t.journalEntryTemplateId ===
                                                              templateId
                                                      )?.content ?? "";
                                            setSelectedTemplateId(templateId);
                                            setEntryText(content);
                                        }}
                                    >
                                        {templateOptions.map((template) => (
                                            <MenuItem
                                                value={template.journalEntryTemplateId}
                                                key={template.journalEntryTemplateId}
                                            >
                                                {template.subject}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </React.Fragment>
                        ) : null}
                        <TextField
                            id="journal-input"
                            onChange={(evt) => setEntryText(evt.target.value)}
                            multiline
                            rows={8}
                            value={entryText}
                            defaultValue=""
                            sx={{ width: 1 }}
                        >
                            {entryText}
                        </TextField>
                        {props.journalHistory.length > 0 && (
                            <Box>
                                <Typography variant="h6">Journal History</Typography>
                                <Box sx={{ maxHeight: "250px", overflowY: "scroll", mb: 1 }}>
                                    <HistoryTimeLine
                                        slimView
                                        events={[]}
                                        journalEntries={props.journalHistory}
                                    />
                                </Box>
                            </Box>
                        )}
                        <CclDrawerActionBar>
                            <CclLoadingButton
                                mode={"primary"}
                                onClick={handleSave}
                                disabled={
                                    entryText === "" &&
                                    templateOptions.length > 0 &&
                                    selectedTemplateId < 0
                                }
                                loading={isLoading}
                            >
                                Save
                            </CclLoadingButton>
                            <CclButton onClick={props.onClose} mode={"secondary"}>
                                Cancel
                            </CclButton>
                        </CclDrawerActionBar>
                    </Stack>
                </Box>
            </CclDrawer>
        </>
    );
};

export default JournalEntryDrawer;
