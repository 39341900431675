export enum eWhiteCellColors {
    Grey = "#C3C6CF",
    Red = "#BA1A1A",
    Orange = "#EF5800",
    Blue = "#2F5F95",
    Purple = "#A556A2",
    Green = "#5E9D45",
}

export enum eGrayCellColors {
    Grey = "#C3C6CF",
    Red = "#BA1A1A",
    Orange = "#EF5800",
    Blue = "#2F5F95",
    Purple = "#A556A2",
    Green = "#4B7E37",
}
