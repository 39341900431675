import * as React from "react";
import { Alert, Box } from "@mui/material";
import { FeatureFlag } from "../../../../featureFlags";
import { useUpdateApplicationFeatureMutation } from "../../../../services/cclTokenedApplicationFeaturesApi";
import { UpdateApplicationFeature } from "../../../../services/types/applicationFeatureTypes";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import ApplicationFeaturesDataGridPro from "./admininistrationDataGrid";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventFeatureDetails } from "../../../../services/types/accessEventTypes";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";

const Administration: React.FC = (props) => {
    const { logEvent } = useLogAccessEvent();
    const [confirmUpdate, setConfirmUpdate] = React.useState<boolean>(false);

    const features = React.useContext(FeatureFlag);
    const claimservice = new UserClaimsService();

    const [update, { isLoading, isError }] = useUpdateApplicationFeatureMutation();

    const toggleFeature = (id: number) => {
        console.log(features);
        const updateFeature: UpdateApplicationFeature = {
            Id: id,
            UserEmail: claimservice.GetCurrentUserEmail(),
        };
        update(updateFeature)
            .unwrap()
            .then(() => {
                const fname =
                    features.allFeatures?.find((f: any) => f.applicationFeatureId === id)?.name ??
                    "Unknown";
                setConfirmUpdate(true);
                const evtData: AccessEventFeatureDetails = {
                    featureId: id.toString(),
                    featureName: fname,
                };
                logEvent("ApplicationFeatureChanged", evtData);
            });
    };

    if (!claimservice.IsUserInAppRole(6)) {
        return (
            <Box>
                <Alert severity="error">Feature Manager Role is required to access this page</Alert>
            </Box>
        );
    }

    return (
        <Box sx={{ width: "100%" }}>
            <ApplicationFeaturesDataGridPro
                features={features.allFeatures}
                triggerUpdateFlow={toggleFeature}
                loading={isLoading}
            />

            <CclAlertSnackbar
                open={confirmUpdate}
                message={isError ? "Feature Update Failed" : "Feature Updated "}
                severity={isError ? "error" : "success"}
                onClose={() => setConfirmUpdate(false)}
            />
        </Box>
    );
};

export default Administration;
