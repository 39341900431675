import * as React from "react";
import { Typography } from "@mui/material";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import AccountManagerPanel from "./clientDetail/accountManager/accountManagerPanel";
import TargetRevenuePanel from "./clientDetail/targetrevenue/targetRevenuePanel";
import DetailPage from "../../components/common/detailPage/detailPage";
import CclGenericConfirmationDialog from "../../components/common/cclGenericConfirmationDialog";
import { UserClaimsService } from "../../services/currentUserService/currentUserService";
import HomeIndustriesPanel from "./clientDetail/homeIndustries/homeIndustriesPanel";
import { FeatureFlag } from "../../featureFlags";

export type ClientsParams = {};

export const Clients: React.FC = (prop) => {
    const [saveNeeded, setSaveNeeded] = React.useState<boolean>(false);
    const [requestedTab, setRequestedTab] = React.useState<number>(0);
    const [actualTab, setActualTab] = React.useState<number>(0);
    const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);

    const features = React.useContext(FeatureFlag);

    const tablist: [string, number, React.ReactNode][] = [
        ["Client Mappings", 0, <HomeIndustriesPanel />],
        ["Account Managers", 1, <AccountManagerPanel />],
        ["Target Revenues", 2, <TargetRevenuePanel />],
        ["Events", 3, <></>]
    ];

    const claimsService = new UserClaimsService();

    const handleTabChangeRequest = (event: React.SyntheticEvent, newValue: number) => {
        if (!saveNeeded) {
            setActualTab(newValue);
            return;
        }
        // save needed so open dialog
        setRequestedTab(newValue);
        setConfirmOpen(true);
    };

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                {`Customer Hierarchy Tool`}
            </Typography>
        </SimpleBreadcrumbs>
    );
    
    if (!features?.chtTabs?.active) {
        return (
            <React.Fragment>
                <></>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <CclGenericConfirmationDialog
                open={confirmOpen}
                onCancel={() => setConfirmOpen(false)}
                onOk={() => {
                    setActualTab(requestedTab);
                    setSaveNeeded(false);
                    setConfirmOpen(false);
                }}
                msg={"You're changes have not been saved."}
            />
            <DetailPage
                descriptor={"clients"}
                breadcrumbs={breadcrumbs}
                tablist={claimsService.IsReadOnly() ? tablist : tablist}
                handleTabChange={handleTabChangeRequest}
                activeTab={actualTab}
            />
        </React.Fragment>
    );
};

export default Clients;
