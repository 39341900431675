import * as React from "react";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { List, ListItem, ListItemText, Popover, Switch } from "@mui/material";
import { Box } from "@mui/system";
import { InstrumentVisibility } from "./assessmentStatusDataGrid";
import { right } from "@popperjs/core";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import CclButton from "../../../../../../components/common/cclButtons/cclButton";
import CclActionButton from "../../../../../../components/common/cclActionButton/cclActionButton";

export interface AssessmentStatusDataGridToolbarProps {
    hideScoreButton?: boolean;
    instrumentVisibility: InstrumentVisibility[];
    numSelected: number;
    handleButtonClick: (button: string) => void;
    onAssessmentFilterChange: (visible: boolean, instrumentId: number) => void;
    readOnly?: boolean;
    sessionCode: string;
    sessionKey: number;
    isRegistration: boolean;
    inProgressEvent: string | null;
}

const AssessmentStatusDataGridToolbar: React.FC<AssessmentStatusDataGridToolbarProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const toggleVisibility = (visible: boolean, instrumentId: number) => {
        props.onAssessmentFilterChange(visible, instrumentId);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openCclScoring = (evt: React.MouseEvent<HTMLButtonElement>) => {
        props.handleButtonClick("viewscoring");
    };

    const assessmentChecklistOpen = Boolean(anchorEl);
    const id = assessmentChecklistOpen ? "assessment-visiblity-checkbox-popover" : undefined;

    return (
        <GridToolbarContainer>
            <Box sx={{ width: 1, justifyContent: "space-between", padding: 2 }} padding={1}>
                {props.isRegistration ? null : (
                    <CclActionButton
                        disabled={props.numSelected === 0}
                        fixedLabel="Multi-Participant Actions"
                        options={[
                            {
                                val: "createjournalentry",
                                option: "Create Journal Entries",
                                disabled: props.numSelected === 0,
                            },
                            {
                                val: "resendinvitations",
                                option: "Resend Invitations",
                                disabled: props.numSelected === 0,
                            },
                            {
                                val: "sendreminders",
                                option: "Resend Reminders",
                                disabled: props.numSelected === 0,
                            },
                            {
                                val: "resendraterinvitations",
                                option: "Resend Rater Invitations",
                                disabled: props.numSelected === 0,
                            },
                            {
                                val: "sendraterreminders",
                                option: "Resend Rater Reminders",
                                disabled: props.numSelected === 0,
                            },
                        ]}
                        onSelect={async (val: string) => props.handleButtonClick(val)}
                    />
                )}
                {props.hideScoreButton === true ? null : (
                    <CclButton
                        size="small"
                        mode={"primary"}
                        onClick={openCclScoring}
                        sx={{ marginLeft: 3 }}
                    >
                        Score Assessments
                    </CclButton>
                )}
                {props.instrumentVisibility.length > 0 ? (
                    <React.Fragment>
                        <CclButton
                            size="small"
                            onClick={handleClick}
                            endIcon={assessmentChecklistOpen ? <ArrowDropDown /> : <ArrowRight />}
                            sx={{
                                float: right,
                                whiteSpace: "nowrap",
                                display: "inline-flex",
                                flexDirection: "row",
                            }}
                            mode={"primary"}
                        >
                            Filter Assessments
                        </CclButton>
                        <Popover
                            id={id}
                            open={assessmentChecklistOpen}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <List dense>
                                {props.instrumentVisibility
                                    .sort((a: InstrumentVisibility, b: InstrumentVisibility) =>
                                        a.name > b.name ? 1 : -1
                                    )
                                    .map((iv) => (
                                        <ListItem key={iv.id} value={iv.id}>
                                            <Switch
                                                checked={iv.visible}
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                    checked: boolean
                                                ) => toggleVisibility(checked, iv.id)}
                                                size="small"
                                                sx={{ p: "5px", mr: "4px" }}
                                            />
                                            <ListItemText primary={iv.name} />
                                        </ListItem>
                                    ))}
                            </List>
                        </Popover>
                    </React.Fragment>
                ) : null}
            </Box>
        </GridToolbarContainer>
    );
};

export default AssessmentStatusDataGridToolbar;
