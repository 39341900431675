import * as React from "react";
import {
    DataGridPremium,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridColDef,
    GridColumnGroup,
    GridColumnGroupingModel,
    GridColumnVisibilityModel,
    GridRenderCellParams,
    GridSelectionModel,
    useGridApiRef,
} from "@mui/x-data-grid-premium";
import { Alert, Badge, Chip, IconButton, Link } from "@mui/material";
import { Box, Stack } from "@mui/system";
import {
    ParticipantAdditionalInstrumentStatus,
    ProgramParticipantScoringStatus,
} from "../../../../../../services/types/scoringStatusTypes";
import AssessmentStatusGroupHeader from "./assessmentStatusGroupHeader";
import RaterStatusCell from "./raterStatusCell";
import ScoringStatusCell from "./scoreStatusCell";
import AssessmentStatusDataGridToolbar from "./assessmentStatusDataGridToolbar";
import { useGetAppUrlsQuery } from "../../../../../../services/cclTokenedSettingsApi";
import {
    AccessEventRegistrationDetails,
    AccessEventSessionDetails,
} from "../../../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../../../hooks/useLogAccessEvent";
import SelfStatusCell, { SelfStatusCellProps } from "./selfStatusCell";
import CclDataGridActionMenu from "../../../../../../components/common/cclDataGridActionMenu/cclDataGridActionMenu";

export interface InstrumentVisibility {
    visible: boolean;
    collapsed: boolean;
    showAllRaters: boolean;
    id: number;
    name: string;
}

export interface InstrumentInfo {
    instrumentId: number;
    hasRaters: boolean;
    name: string;
    shortName: string;
    raters: InstrumentRaterInfo[];
}

export interface InstrumentRaterInfo {
    raterId: number;
    raterName: string;
    minRequired: number;
    minRecommended: number;
    received: number;
    receivedLate: number;
}

export interface AssessmentStatusDataGridProps {
    hideScoreButton?: boolean;
    instruments: InstrumentInfo[];
    participants: ProgramParticipantScoringStatus[];
    isLoading: boolean;
    showAllRatersByDefault: boolean;
    sessionKey: number | undefined;
    sessionCode: string | undefined;
    isRegistration: boolean;
    onScorePressed: (selectedParticipantIds: number[]) => void;
    handleEvent: (event: string, participantIds: number[]) => Promise<void>;
    handleAdditionalClick: (additionalInstruments: ParticipantAdditionalInstrumentStatus[]) => void;
    readOnly?: boolean;
}

const AssessmentStatusDataGrid: React.FC<AssessmentStatusDataGridProps> = (props) => {
    const [currentEvent, setCurrentEvent] = React.useState<string | null>(null);
    const { logEvent } = useLogAccessEvent();
    const apiRef = useGridApiRef();
    const singleParticipant = props.participants.length === 1;
    const { data: urls, isSuccess } = useGetAppUrlsQuery();
    const [selectedParticipantIds, setSelectedParticipantIds] = React.useState<number[]>(
        singleParticipant
            ? props.participants.map((p) => {
                  return p.participantId;
              })
            : []
    );
    const [columnViewStatus, setColumnViewStatus] = React.useState<InstrumentVisibility[]>([]);
    const [columnVisibilityModel, setColumnVisibilityModel] =
        React.useState<GridColumnVisibilityModel>({});

    const raterColWidthWide = 100;
    const raterColWidthNarrow = 70;
    const selfAndScoreColWidth = 114;

    const colDefs: GridColDef[] = React.useMemo(() => {
        let columns: GridColDef[] = [];
        if (
            props?.instruments != null &&
            props.instruments.length > 0 &&
            columnViewStatus.length > 0
        ) {
            if (!singleParticipant) {
                columns = columns.concat([
                    {
                        ...GRID_CHECKBOX_SELECTION_COL_DEF,
                    },
                ]);
            }

            columns = columns.concat([
                {
                    field: "participant",
                    headerAlign: "left",
                    headerName: "Participant",
                    type: "string",
                    groupingValueGetter: (params) => {
                        return `${params.row.firstName} ${params.row.lastName}`;
                    },
                    valueGetter: (params) => {
                        return `${params.row.firstName} ${params.row.lastName}`;
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        if (props.hideScoreButton === true) {
                            return params.value;
                        } else {
                            return (
                                <Box
                                    width="100%"
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    display="flex"
                                >
                                    <Link href={`/registration/${params.row.participantId}`}>
                                        {params.value}
                                    </Link>
                                    {params.row.additionalInstruments.length > 0 ? (
                                        // <Badge
                                        //     badgeContent={params.row.additionalInstruments.length} // The number to display
                                        //     color="primary" // Badge color
                                        //     max={9} // Maximum number to display (e.g., "99+")
                                        //     overlap="circular" // Badge shape (circular or rectangular)
                                        //     sx={{ float: "right", marginTop: "10px" }}
                                        // >
                                        //     {/* <IconButton>
                                        //         <NotificationsIcon />
                                        //     </IconButton> */}
                                        // </Badge>
                                        <Chip
                                            label="more"
                                            color="primary"
                                            variant="filled"
                                            size="small"
                                            sx={{
                                                float: "right",
                                                borderRadius: "16px",
                                                padding: "0px",
                                            }}
                                            onClick={() => {
                                                props.handleAdditionalClick(
                                                    params.row.additionalInstruments
                                                );
                                            }}
                                        />
                                    ) : null}
                                </Box>
                            );
                        }
                    },
                    maxWidth: 275,
                    minWidth: 150,
                    flex: 1.0,
                },
                {
                    field: "actions",
                    headerName: "",
                    width: 40,
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <CclDataGridActionMenu
                                id={params.row.participantId.toString()}
                                menuItems={[
                                    {
                                        label: "Create Journal Entry",
                                        id: "createjournalentry",
                                    },
                                    {
                                        label: "View Contact Info",
                                        id: "viewparticipantcontactinfo",
                                    },
                                    {
                                        label: "Edit Key Dates",
                                        id: "editkeydates",
                                    },
                                    {
                                        label: "Resend Invitation",
                                        id: "resendinvitation",
                                    },
                                    {
                                        label: "Resend Reminder",
                                        id: "sendreminder",
                                    },
                                    {
                                        label: "Resend Raters Invitations",
                                        id: "resendraterinvitations",
                                    },
                                    {
                                        label: "Resend Raters Reminders",
                                        id: "sendraterreminders",
                                    },
                                ]}
                                onSelect={async (val: string) => {
                                    await props.handleEvent(val, [params.row.participantId]);
                                }}
                            />
                        );
                    },
                },
            ]);

            props.instruments.forEach((inst, index) => {
                if (inst.hasRaters) {
                    // add the columns we know we need
                    columns = columns.concat([
                        {
                            field: `${inst.instrumentId}.scorable`,
                            headerAlign: "center",
                            headerName: "Scorability",
                            headerClassName: "superHeader",
                            cellClassName: "whiteCell",
                            type: "string",
                            renderCell: (params: GridRenderCellParams) => (
                                <ScoringStatusCell
                                    status={getScorability(params.row, inst.instrumentId) ?? 0}
                                />
                            ),
                            maxWidth: selfAndScoreColWidth,
                            minWidth: selfAndScoreColWidth,
                            disableColumnMenu: true,
                            sortable: false,
                        },
                        {
                            field: `${inst.instrumentId}.self`,
                            headerAlign: "center",
                            headerName: "Self",
                            headerClassName: "superHeader",
                            cellClassName: "whiteCell",
                            type: "string",
                            renderCell: (params: GridRenderCellParams) => (
                                <SelfStatusCell
                                    {...getSelfStatusParams(params.row, inst.instrumentId)}
                                />
                            ),
                            maxWidth: selfAndScoreColWidth,
                            minWidth: selfAndScoreColWidth,
                            disableColumnMenu: true,
                            sortable: false,
                        },
                    ]);

                    const raters =
                        props.instruments.find((i) => i.instrumentId === +inst.instrumentId)
                            ?.raters ?? [];

                    raters
                        .filter((r) => r != null)
                        .forEach((r, index) => {
                            columns.push({
                                field: `${inst.instrumentId}.${r?.raterName.toLowerCase()}`,
                                headerAlign: "center",
                                renderHeader: () => {
                                    return (
                                        <div
                                            style={{
                                                lineHeight: "1.5em",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    justifyContent: "center",
                                                    display: "flex",
                                                }}
                                            >
                                                {r?.raterName}
                                            </span>
                                            <span
                                                style={{
                                                    justifyContent: "center",
                                                    display: "flex",
                                                }}
                                            >{`${r.minRequired}/${r.minRecommended}`}</span>
                                        </div>
                                    );
                                },
                                headerClassName: "greyCellHeader",
                                align: "left",
                                cellClassName: "greyCell",
                                type: "string",
                                renderCell: (params: GridRenderCellParams) => (
                                    <RaterStatusCell
                                        participant={params.row}
                                        instrumentId={inst.instrumentId}
                                        raterId={r.raterId}
                                        isWide={r?.raterName.length > 10}
                                    />
                                ),
                                minWidth:
                                    r?.raterName.length > 10
                                        ? raterColWidthWide
                                        : raterColWidthNarrow,
                                maxWidth:
                                    r?.raterName.length > 10
                                        ? raterColWidthWide
                                        : raterColWidthNarrow,
                                disableColumnMenu: true,
                                sortable: false,
                            });
                        });
                } else {
                    columns = columns.concat([
                        {
                            field: `${inst.instrumentId}.self`,
                            headerAlign: "center",
                            headerName: "Self",
                            headerClassName: "superHeader",
                            cellClassName: "whiteCell",
                            type: "string",
                            renderCell: (params: GridRenderCellParams) => (
                                <SelfStatusCell
                                    {...getSelfStatusParams(params.row, inst.instrumentId)}
                                />
                            ),
                            maxWidth: selfAndScoreColWidth,
                            minWidth: selfAndScoreColWidth,
                            disableColumnMenu: true,
                            sortable: false,
                        },
                    ]);
                }
            });
        }
        return columns;
    }, [props, columnViewStatus.length, singleParticipant]);

    const generateVisibilityModel = React.useCallback(() => {
        let newVisibilityModel = {};
        props.instruments.forEach((inst) => {
            const viewStatus = columnViewStatus.find((i) => i.id === inst.instrumentId);

            Object.assign(newVisibilityModel, {
                [`${inst.instrumentId}.self`]:
                    viewStatus != null ? !viewStatus.collapsed && viewStatus.visible : true,
            });
            if (inst.hasRaters) {
                const raters = inst.raters.filter((r) => r.raterName.toLowerCase() !== "self");
                const raterSummaryVisible =
                    viewStatus != null
                        ? !viewStatus.collapsed && !viewStatus.showAllRaters && viewStatus.visible
                        : !props.showAllRatersByDefault;
                const indRatersVisible =
                    viewStatus != null
                        ? !viewStatus.collapsed && viewStatus.showAllRaters && viewStatus.visible
                        : props.showAllRatersByDefault;

                Object.assign(newVisibilityModel, {
                    [`${inst.instrumentId}.scorable`]: viewStatus?.visible ?? true,
                });
                if (raters.length > 0) {
                    Object.assign(newVisibilityModel, {
                        [`${inst.instrumentId}.raters`]: raterSummaryVisible,
                    });
                    raters.forEach((raterCol) => {
                        Object.assign(newVisibilityModel, {
                            [`${inst.instrumentId}.${raterCol.raterName.toLowerCase()}`]:
                                indRatersVisible,
                        });
                    });
                }
            }
        });
        return newVisibilityModel;
    }, [props.instruments, props.showAllRatersByDefault, columnViewStatus]);

    React.useEffect(() => {
        if (props.instruments == null || props.instruments.length <= 0) {
            setColumnViewStatus([]);
            setColumnVisibilityModel({});
        } else if (columnViewStatus.length === 0) {
            setColumnViewStatus(
                props.instruments.map((i) => {
                    return {
                        visible: true,
                        id: i.instrumentId,
                        name: i.name,
                        collapsed: false,
                        showAllRaters: props.showAllRatersByDefault ?? false,
                    };
                })
            );
        }
        setColumnVisibilityModel(generateVisibilityModel());
    }, [
        columnViewStatus,
        generateVisibilityModel,
        props.instruments,
        props.showAllRatersByDefault,
    ]);

    const getScorability = (
        pax: ProgramParticipantScoringStatus,
        instrumentId: number
    ): number | undefined => {
        const paxInstrument = pax.instruments.find((i) => i.instrumentId === +instrumentId);
        if (paxInstrument == null) return 0;
        if (paxInstrument.selfAssessmentStatusTypeId === 5) return 5;
        if (paxInstrument.scoringStatus === 2) return 2;
        if (paxInstrument.reportQualityTypeId === 4) return 1;
        return 0;
    };

    const handleEvent = async (buttonName: string) => {
        setCurrentEvent(buttonName);
        switch (buttonName) {
            case "viewscoring":
                if (isSuccess && props.sessionKey != null) {
                    const evtData: AccessEventSessionDetails | AccessEventRegistrationDetails =
                        props.isRegistration
                            ? {
                                  esiKey:
                                      props.participants[0]?.participantId.toString() ?? "unknown",
                                  imKey:
                                      props.participants[0]?.individualId.toString() ?? "unknown",
                                  email: props.participants[0]?.email ?? "unknown",
                              }
                            : {
                                  sessionID: props.sessionKey?.toString() ?? "unknown",
                                  projectCode: props.sessionCode ?? "unknown",
                              };
                    logEvent("AssessmentsScored", evtData);

                    window.open(
                        `${urls["CCLScoring"]}/program-status/${props.sessionKey}`,
                        "_blank"
                    );
                }
                break;

            case "score":
                props.onScorePressed(selectedParticipantIds);
                break;

            case "createjournalentry":
            case "resendinvitations":
            case "sendreminders":
            case "resendraterinvitations":
            case "sendraterreminders":
            default:
                await props.handleEvent(buttonName, selectedParticipantIds);
                break;
        }
    };

    const getSelfStatusParams = (
        pax: ProgramParticipantScoringStatus,
        instrumentId: number
    ): SelfStatusCellProps => {
        const paxInstrument = pax.instruments.find((i) => i.instrumentId === +instrumentId);
        const st = paxInstrument?.selfAssessmentStatusTypeId ?? 0;
        const numRaters = paxInstrument?.supportedRaterTypeCount ?? 0;
        const scrd =
            paxInstrument?.scoringStatus == null ? false : paxInstrument.scoringStatus === 2;
        return { status: st, hasRaters: numRaters > 1, scored: scrd };
    };

    const assessmentVisibilityChange = (visible: boolean, instrumentId: number) => {
        const inst = props.instruments.find((i) => i.instrumentId === instrumentId);
        if (inst == null) return;
        let newViewStatus = columnViewStatus.filter((vs) => vs.id !== instrumentId);
        let colViewStatus = columnViewStatus.find((vs) => vs.id === instrumentId);
        if (colViewStatus == null) {
            newViewStatus.push({
                id: inst.instrumentId,
                name: inst.name,
                visible: visible,
                collapsed: !inst.hasRaters,
                showAllRaters: !inst.hasRaters,
            });
        } else {
            newViewStatus.push({
                id: colViewStatus.id,
                name: colViewStatus.name,
                visible: visible,
                collapsed: colViewStatus.collapsed,
                showAllRaters: colViewStatus.showAllRaters,
            });
        }
        setColumnViewStatus(newViewStatus);
    };

    const handleDetailLevelChange = (detailLevelSetting: number) => {
        const collapsed: boolean = detailLevelSetting === 0;
        const showAllRaterCategories = detailLevelSetting === 2;
        const withRaters = props.instruments.filter((i) => i.hasRaters);
        let newViewStatus = columnViewStatus.filter(
            (vs) => withRaters.find((i) => vs.id === i.instrumentId) == null
        );
        withRaters.forEach((i) => {
            const currViewStatus = columnViewStatus.find((s) => s.id === i.instrumentId);
            newViewStatus.push({
                id: i.instrumentId,
                name: i.name,
                visible: currViewStatus?.visible ?? true,
                collapsed: collapsed,
                showAllRaters: showAllRaterCategories,
            });
        });
        setColumnViewStatus(newViewStatus);
    };

    let colGroupingModel: GridColumnGroupingModel = [];

    if (props.instruments.length > 0) {
        let columnGroup: GridColumnGroup[] = props.instruments.map((inst, index) => {
            let children = [{ field: `${inst.instrumentId}.self` }];
            if (inst.hasRaters) {
                children.push({ field: `${inst.instrumentId}.scorable` });
                const raters =
                    props.instruments.find((i) => i.instrumentId === +inst.instrumentId)?.raters ??
                    [];
                if (raters.length > 0) children.push({ field: `${inst.instrumentId}.raters` });

                raters.forEach((r) => {
                    children.push({ field: `${inst.instrumentId}.${r.raterName.toLowerCase()}` });
                });
            }

            return {
                groupId: inst.shortName,
                children: children,
                headerAlign: "center",
                headerClassName: "superHeader",
                renderHeaderGroup: (params) => {
                    return (
                        <AssessmentStatusGroupHeader
                            {...params}
                            headerText={inst.hasRaters ? inst.name : inst.shortName}
                            hoverTitle={`${inst.name} (${inst.shortName})`}
                        />
                    );
                },
            };
        });
        colGroupingModel = columnGroup.sort((a: GridColumnGroup, b: GridColumnGroup) =>
            a.groupId.localeCompare(b.groupId)
        );
    }

    if (
        props.instruments == null ||
        props.instruments.length === 0 ||
        props.participants == null ||
        props.participants.length === 0
    ) {
        return (
            <Stack width={1} spacing={2}>
                <Alert severity="info">
                    No assessment status information found for this session.
                </Alert>
            </Stack>
        );
    }
    const sortedParticipants = [...props.participants].sort((a, b) =>
        a.firstName.localeCompare(b.firstName)
    );

    return (
        <Stack width={1} spacing={2}>
            <DataGridPremium
                getRowId={(row) => row.participantId}
                experimentalFeatures={{ columnGrouping: true }}
                loading={props.isLoading}
                components={{ Toolbar: AssessmentStatusDataGridToolbar }}
                componentsProps={{
                    toolbar: {
                        hideScoreButton: props.hideScoreButton,
                        isRegistration: props.isRegistration,
                        numSelected: selectedParticipantIds.length,
                        handleButtonClick: async (buttonName: string) => handleEvent(buttonName),
                        instrumentVisibility: columnViewStatus,
                        onAssessmentFilterChange: assessmentVisibilityChange,
                        defaultDetailLevel: 2, //1,
                        handle360DetailChange: handleDetailLevelChange,
                        showDetailLevelSelect: false, //!noInstrumentWithRaters,
                        readOnly: props.readOnly,
                        sessionCode: props.sessionCode,
                        sessionKey: props.sessionKey,
                        inProgressEvent: currentEvent,
                    },
                }}
                onSelectionModelChange={(ids: GridSelectionModel) => {
                    setSelectedParticipantIds([...ids.map((i) => i as number)]);
                }}
                apiRef={apiRef}
                checkboxSelection={!singleParticipant}
                columnGroupingModel={colGroupingModel}
                columnVisibilityModel={columnVisibilityModel}
                columns={colDefs}
                rows={sortedParticipants}
                disableSelectionOnClick={true}
                sx={{
                    "& .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                    },
                    "& .greyCell": {
                        backgroundColor: "#F0F1F3",
                        paddingLeft: "2px",
                    },
                    "& .greyCellHeader": {
                        backgroundColor: "#F0F1F3",
                        borderLeft: "1px solid #E0E0E0",
                        paddingLeft: "2px",
                        paddingRight: "2px",
                    },
                    "& .superHeader": {
                        borderLeft: "1px solid #E0E0E0",
                    },
                    "& .whiteCell": {
                        paddingLeft: "4px",
                    },
                    "& .MuiDataGrid-pinnedColumns": {
                        backgroundColor: "#FFFFFF",
                    },
                    "& .MuiDataGrid-pinnedColumnHeaders": {
                        backgroundColor: "#FFFFFF",
                    },
                }}
                autoHeight
                initialState={{
                    sorting: { sortModel: [{ field: "name", sort: "asc" }] },
                    pinnedColumns: { left: ["__check__", "participant", "actions"] },
                }}
                disableRowGrouping
                disableExtendRowFullWidth
            />
        </Stack>
    );
};

export default AssessmentStatusDataGrid;
