import * as React from "react";
import {
    CircularProgress,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export interface CclDataGridActionMenuProps {
    id: string;
    menuItems: { label: string; id: string }[];
    onSelect: (cmd: string) => void;
}

const CclDataGridActionMenu: React.FC<CclDataGridActionMenuProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedId, setSelectedId] = React.useState<string | null>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleChange = async (val: string) => {
        setSelectedId(val);
        await props.onSelect(val);
        setSelectedId(null);
        setAnchorEl(null);
    };

    return (
        <React.Fragment key={props.id}>
            <IconButton
                id={`menu-button-${props.id}`}
                aria-controls={`menu-${props.id}`}
                aria-haspopup="true"
                onClick={handleMenuOpen}
            >
                <MoreVertIcon />
            </IconButton>
            <Popover
                id={`${props.id}-row-menu`}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <List dense component="nav">
                    {props.menuItems &&
                        props.menuItems.length > 0 &&
                        props.menuItems.map((item) => (
                            <ListItemButton
                                key={`${props.id}-${item.id}`}
                                onClick={() => handleChange(item.id)}
                            >
                                {item.id === selectedId ? (
                                    <ListItemIcon sx={{ minWidth: "auto", mr: 2 }}>
                                        <CircularProgress size={20} />
                                    </ListItemIcon>
                                ) : null}
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        ))}
                </List>
            </Popover>
        </React.Fragment>
    );
};

export default CclDataGridActionMenu;
