import * as React from "react";
import { Alert, Box, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import RaterManagementPanel from "../../../registrations/registrationDetail/raterManagement/raterManagementPanel";
import { SessionDetailType } from "../../../../services/types/sessionDetailTypes";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { DetailsTabPanel } from "../../../../components/common/detailTabPanel/detailTabPanel";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import CclInfoTable from "../../../../components/common/cclInfoTable/cclInfoTable";
import { useGetSessionSetUpByEventKeyQuery } from "../../../../services/cclTokenedSessionApi";
import { Participant } from "../../../../services/types/enterpriseParticipantApiTypes";
import AssessmentListPanel from "./assessmentList/assessmentListPanel";
import ScoringStatusPanel from "../../../sessions/sessionDetail/assessments/scoringStatus/scoringStatusPanel";
import { CclInfoTblAssessmentStatusSessionDatesTemplate } from "../../../sessions/sessionDetail/assessments/scoringStatus/cclInfoTblAssessmentStatusSessionDatesTemplate";
import { cclInfoTblAssessmentStatusSessionInfoTemplate } from "../../../sessions/sessionDetail/assessments/scoringStatus/cclInfoTblAssessmentStatusSessionInfoTemplate";

interface RegistrationAssessmentsPanelProps {
    sessionDetails: SessionDetailType | undefined;
    registration: Participant | undefined;
    handleJournalEntry: (keys: string[]) => void;
    readOnly?: boolean;
}

const RegistrationAssessmentsPanel: React.FC<RegistrationAssessmentsPanelProps> = (props) => {
    const [activeTab, setActiveTab] = React.useState<number>(0);
    const { data: sessionSetupData } = useGetSessionSetUpByEventKeyQuery(
        props.sessionDetails?.sessionKey.toString() ?? "",
        {
            skip: props.sessionDetails == null,
        }
    );

    const claimsService = new UserClaimsService();

    const handleTabChange = (event: React.SyntheticEvent, newValue: number | null) => {
        if (newValue != null) setActiveTab(newValue);
    };

    let tablist: [string, number, React.ReactNode][] = [
        ["Assessment List", 0, <AssessmentListPanel registration={props.registration} />],
        [
            "Assessment Status",
            1,
            <ScoringStatusPanel
                sessionKey={props.sessionDetails?.sessionKey}
                sessionCode={props.sessionDetails?.sessionCode}
                handleJournalEntry={props.handleJournalEntry}
                participant={props.registration}
                readOnly={props.readOnly}
            />,
        ],
        [
            "Rater Information",
            2,
            <RaterManagementPanel
                sessionKey={props.sessionDetails?.sessionKey ?? 0}
                programCode={props.sessionDetails?.sessionCode ?? ""}
                registration={props.registration}
                readOnly={!claimsService.IsUserInAppRole(3) || props.readOnly}
            />,
        ],
    ];

    if (props.sessionDetails === undefined) {
        return (
            <Box>
                <Alert severity="error">Error: Session data not found!</Alert>
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    width: 1,
                    height: 1,
                    m: 2,
                    marginTop: 2,
                    borderWidth: 0,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    sx={{ borderWidth: 0, flexGrow: 1 }}
                >
                    {tablist.length > 1 ? (
                        <ToggleButtonGroup
                            color="primary"
                            value={activeTab}
                            exclusive
                            onChange={handleTabChange}
                            sx={{ mb: 3 }}
                        >
                            {tablist.map((tab, i) => (
                                <ToggleButton
                                    key={i}
                                    id={`session-details-assessments-tab-${i}`}
                                    value={i}
                                >
                                    {tab[0]}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    ) : null}
                </Box>
                <CclGenericAccordian
                    headingText="Session Information"
                    defaultExpanded={false}
                    noGutter={true}
                    sx={{ m: 2 }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <CclInfoTable
                                data={props.sessionDetails ?? []}
                                template={cclInfoTblAssessmentStatusSessionInfoTemplate}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CclInfoTable
                                data={sessionSetupData ?? []}
                                template={CclInfoTblAssessmentStatusSessionDatesTemplate}
                            />
                        </Grid>
                    </Grid>
                </CclGenericAccordian>
                {tablist.map((tab, i) => (
                    <DetailsTabPanel
                        detailType={"session-details-assessments"}
                        key={tab[0]}
                        value={activeTab}
                        index={i}
                    >
                        {tab[2]}
                    </DetailsTabPanel>
                ))}
            </Box>
        </>
    );
};

export default RegistrationAssessmentsPanel;
