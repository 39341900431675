import React, { useState } from "react";
import { Config } from "./config";
import { useGetAllApplicationFeaturesQuery } from "./services/cclTokenedApplicationFeaturesApi";

interface FeatureFlagType {
    [key: string]: any,

};

export const FeatureFlag = React.createContext<FeatureFlagType>(null as unknown as FeatureFlagType);

type FeatureProviderProps = {
    isAuthorized: boolean;
    children?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
};

export const FeatureFlagProvider = (props: FeatureProviderProps) => {
    const envType = Config.REACT_APP_APPLICATION_BUILD_ID?.split("-")[0];

    const { data: allFeatures, isLoading, refetch } = useGetAllApplicationFeaturesQuery();

    React.useEffect(() => {
        if (!props.isAuthorized) {
            return;
        }
        if (!allFeatures || isLoading) {
            if (!allFeatures && !isLoading) {
                refetch();
            }
            return;
        }

        // Dynamically construct tempFeatures
        const tempFeatures = allFeatures.reduce((acc: any, feature) => {
            acc[feature.tag] = feature; // Dynamically add each feature by its name
            return acc;
        }, {
            envType: envType ? envType : "prod", // Add envType as a default property
            allFeatures: allFeatures,           // Include allFeatures as a default property
        });

        // Update the state with the dynamic object
        setFeatures(tempFeatures);
        // eslint-disable-next-line
    }, [allFeatures, isLoading, envType]);

    const [features, setFeatures] = useState({});

    return <FeatureFlag.Provider value={features}>{props.children}</FeatureFlag.Provider>;
};
