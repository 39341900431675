import React from "react";
import JournalEntryDrawer from "../../../components/common/jouralEntryDrawer/journalEntryDrawer";
import CclAlertDialog from "../../../components/common/cclAlertDialog";
import { Link, Typography } from "@mui/material";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import { AccessEventRegistrationDetails } from "../../../services/types/accessEventTypes";
import { useSearchJournalEntriesMutation } from "../../../services/cclTokenedJournalEntryApi";
import { JournalEntry } from "../../../services/types/journalEntryTypes";

export type RegistrationJournalDrawerProps = {
    esiKeys: string[];
    imKey: string;
    email: string;
    sessionCode: string;
    open: boolean;
    onClose: () => void;
    onSave: () => void;
};

const RegistrationJournalDrawer: React.FC<RegistrationJournalDrawerProps> = (props) => {
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const { logEvent } = useLogAccessEvent();

    const [allJournalEntries, setAllJournalEntries] = React.useState<JournalEntry[]>([]);

    const [runSearchJournalEntries] = useSearchJournalEntriesMutation();

    const catData = { esiKey: props.esiKeys, imKey: props.imKey, sessionCode: props.sessionCode };

    React.useEffect(() => {
        if (props.esiKeys.length === 0 || props.esiKeys.length > 1) return;
        let entries: JournalEntry[] = [];

        runSearchJournalEntries({
            journalCategoryIds: [3],
            categoryDataTerm: props.esiKeys[0],
        })
            .unwrap()
            .then((journalentries: JournalEntry[]) => {
                entries = [...journalentries];
                setAllJournalEntries(entries);
            })
            .catch(() => {
                setAllJournalEntries(entries);
            });
    }, [props, runSearchJournalEntries]);

    function saveEntry() {
        const evtData: AccessEventRegistrationDetails = {
            esiKey: props.esiKeys[0],
            imKey: props.imKey,
            email: props.email,
        };
        logEvent("RegistrationJournalEntryCreated", evtData);
        props.onSave();
    }

    return (
        <>
            <CclAlertDialog
                open={alertOpen}
                onOk={() => setAlertOpen(false)}
                severity={"error"}
                title="Error Saving Journal Entry"
                msg={alertMessage}
            />
            {props.open ? (
                <JournalEntryDrawer
                    bannerElement={
                        <Typography sx={{ padding: 2 }}>
                            <span style={{ fontWeight: "bold" }}>{props.esiKeys.length}</span>{" "}
                            participants selected
                        </Typography>
                    }
                    journalCategory={3}
                    journalCategoryData={[JSON.stringify(catData)]}
                    journalHistory={
                        props.esiKeys.length === 0 || props.esiKeys.length > 1
                            ? []
                            : allJournalEntries
                    }
                    alert={
                        <Typography>
                            Please note: Any journal entries entered here will not display in IAS.
                            This feature is for proof of concept and user feedback purpose only.
                            Journaling will remain in IAS until this proof of concept is finalized,
                            including templates for journal entries. If you wish to provide any
                            feedback please contact Andy Wilson (
                            <Link href="mailto:wilsona@ccl.org">wilsona@ccl.org</Link>).
                        </Typography>
                    }
                    alertColor="error"
                    open={true}
                    onClose={() => props.onClose()}
                    onSave={() => saveEntry()}
                    onSaveError={(msg: string) => setAlertMessage(msg)}
                />
            ) : null}
        </>
    );
};

export default RegistrationJournalDrawer;
